<template>
  <div>
    <h4 class="page-title">目录管理</h4>
    <a-row class="rows">
      <a-col class="right" span="29" :offset="0">
        <CourseContents @setChildInit="getChildInit" :courseId="Number(this.$route.query.id)" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import CourseContents from "./CourseContents/index.vue"
export default {
  // 可用组件的哈希表
  components: {CourseContents},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    // 向父页面更新目录修改状态
    getChildInit(saveType){
      this.$parent.onChildInit('sectionInfo', saveType);
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import "./style.less";
</style>
