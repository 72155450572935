<template>
  <!-- 章节目录维护 -->
  <div >
    <div class="rows" style="margin-top:0">
      <div class="rows radio">
        <div class="left required" >目录设置</div>
        <div class="right" span="20">
          <a-radio-group class="radioGroup" v-model="setCatalogue">
            <a-radio :value="0">无需目录</a-radio>
            <a-radio :value="1">需要添加目录</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="head" v-if="setCatalogue">
        <div class="left required">课程目录设置</div>
        <div>
          <a v-if="!listData.length" style="margin-right:30px;" href="https://cos.hxclass.cn/prod/template/course/%E8%AF%BE%E7%A8%8B%E7%9B%AE%E5%BD%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx" target="_blank" rel="noopener noreferrer">
            <a-button>下载目录模版</a-button>
          </a>
          <a v-if="false" style="margin-right:30px;" href="https://cos.hxclass.cn/prod/template/exam/%E9%A2%98%E7%9B%AE%E6%A8%A1%E6%9D%BF.xls" target="_blank" rel="noopener noreferrer">
            <a-button>下载题目模版</a-button>
          </a>
          <a-upload
            :showUploadList="false"
            name="CatalogTemplate"
            :customRequest="ImportCatalogTemplate"
            style="margin-right:30px;"
            v-if="!listData.length"
          >
            <a-button type="primary">导入目录</a-button>
          </a-upload>
          <a-button v-if="false" type="primary" style="margin-right:30px;">导入目录</a-button>
          <a-switch v-model="videoFinishFlag" @change="updateVideoType" style="margin-right:30px;" checked-children="持续更新中" un-checked-children="课程已完结" />
          <a-button @click="openModal({
            title: '新建目录',
            width: '500px',
            type: 'newDirectory'
          })">新建目录</a-button>
        </div>
      </div>
      <!-- 视频管理 -->
      <div class="rows" v-if="setCatalogue">
        <div class="right" v-if="(listData && listData.length) || loading">
          <a-table
            style="margin-top:20px;"
            class="table-template"
            childrenColumnName="childList"
            :loading="loading"
            :expandIcon="expandIcon"
            :columns="columns"
            :data-source="listData"
            :defaultExpandAllRows="true"
            :pagination="false"
            :key="tabelKey"
            :rowKey="item=>item.uid"
          >
            <!-- 主讲人 -->
            <template slot="teacherIdList" slot-scope="item">
              {{funTeacherIdList(item)}}
            </template>

            <!-- 时长 -->
            <template slot="duration" slot-scope="item">
              {{formatSeconds(item)}}
            </template>

            <!-- 终端展示排序 -->
            <template slot="show" slot-scope="item,row">
              <!-- <template v-if="!(row.first==1&&row.last==1)">
                <a v-if="row.first!=1" @click="moveCatalogue(row.uid,1)">前移</a>
                <span v-if="row.first==0 && row.last==0"> | </span>
                <a v-if="row.last!=1" @click="moveCatalogue(row.uid,2)">后移</a>
              </template>
              <span v-else>-</span> -->
              <a @click="moveCatalogue(row.uid,1,row.parentId)">前移</a>
              <span> | </span>
              <a @click="moveCatalogue(row.uid,2,row.parentId)">后移</a>
            </template>

            <!-- 目录操作 -->
            <template slot="Directory" slot-scope="item,row">
              <a @click="openModal({
                title: '新建子目录',
                width: '500px',
                type: 'newChildrenDirectory'
              },row)">新建子目录</a>
              <span> | </span>
              <a @click="openModal({
                title: '编辑目录',
                width: '500px',
                type: 'editDirectory'
              },row)">编辑</a>
              <span> | </span>
              <a-popconfirm
                  title="是否确认删除当前章节?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="removeCatalogue(row)"
                >
                  <a href="#">删除</a>
              </a-popconfirm>
            </template>

            <!-- 视频操作 -->
            <template slot="videoFileId" slot-scope="item,row">
              <template v-if="!!item">
                <a @click="openModal({
                  title: '上传视频',
                  width: '500px',
                  type: 'uploadVideo'
                },row)">编辑</a>
                <!-- <span> | </span>
                <a-popconfirm
                    title="是否确认删除章节视频?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="removeVideo(row)"
                  >
                    <a href="#">删除</a>
                </a-popconfirm> -->
              </template>
              <a v-else @click="openModal({
                title: '上传视频',
                width: '500px',
                type: 'uploadVideo'
              },row)">上传</a>
            </template>

            <!-- 小节自测操作 -->
            <template slot="SelfTest" slot-scope="item,row">
              <template>
                <a-upload
                  :showUploadList="false"
                  :name="'TopicTemplate,'+row.uid"
                  :customRequest="fileAction"
                >
                  <a>导入</a>
                </a-upload>
                <span> | </span>
                <template v-if="(row.questionIdList && row.questionIdList.length>0) || (row.questionList && row.questionList.length>0)">
                  <a @click="$refs.Modal.open({
                    title: '编辑小节自测题目',
                    width: '1000px',
                    type: 'SectionTest',
                    state: 2
                  },row)">编辑</a>
                  <span> | </span>
                  <a @click="removeSectionTest(row.uid)">删除</a>
                </template>
                <a v-else @click="$refs.Modal.open({
                  title: '选择小节自测题目',
                  width: '1000px',
                  type: 'SectionTest'
                },row)">新建</a>
              </template>
            </template>
          </a-table>
        </div>
        <div v-else>
          <a-empty description="暂无目录" />
        </div>
      </div>
    </div>
    <div class="submitBtn" v-if="setCatalogue == 1 && listData && listData.length">
      <a-button type="primary" @click="onSubmit(1)" style="margin-right: 30px" v-if="$route.query.status == 1" :loading="loading">暂存</a-button>
      <a-button @click="onSubmit(2)" :loading="loading">保存</a-button>
    </div>
    <div v-else-if="setCatalogue == 0">
      <a-button type="primary" @click="onSubmit(1)" style="margin-right: 30px" v-if="$route.query.status == 1" :loading="loading">暂存</a-button>
      <a-button @click="onSubmit(2)" :loading="loading">保存</a-button>
    </div>
    <a-modal v-model="modalObj.visible" @cancel="onCancel" :title="modalObj.title" :width="modalObj.width" :footer="null" :confirmLoading="confirmLoading">
      <!-- 新建课程目录 -->
      <template v-if="modalObj.type=='newDirectory' || modalObj.type=='editDirectory'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">目录名称：</a-col>
          <a-col span="20">
            <a-input placeholder="请输入目录名称" v-model="name"/>
          </a-col>
        </a-row>
      </template>

      <!-- 新建子课程目录 -->
      <template v-else-if="modalObj.type=='newChildrenDirectory'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">当前选中：</a-col>
          <a-col span="20" style="line-height: 32px;">{{modalObj.sectionName}}</a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">目录名称：</a-col>
          <a-col span="20">
            <a-input placeholder="请输入子目录名称" v-model="name"/>
          </a-col>
        </a-row>
      </template>

      <!-- 上传课程视频 -->
      <template v-else-if="modalObj.type=='uploadVideo'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">视频：</a-col>
          <a-col span="20">
            <a-upload
              name="videoFileId"
              :showUploadList="false"
              :customRequest="customRequest"
            >
              <a-button>
                <template v-if="uploadLoading">
                  <a-icon type="loading" />
                  <span>上传中 {{(percent*100).toFixed(0)}}%</span>
                </template>
                <template v-else>
                  <a-icon type="upload" />
                  <span>选择视频文件</span>
                </template>
              </a-button>
            </a-upload>
            <p v-if="videoFileId">
              <span>上传成功：</span>
              <span>{{videoFileId}}</span>
            </p>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">授课老师：</a-col>
          <a-col span="20">
            <a-select
              style="width:100%;"
              mode="multiple"
              v-model="teacherChecked"
              :filterOption="filterInstructorList"
              placeholder="请选择授课老师(可多选)"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option :value="item.teacherId" v-for="item in InstructorList" :key="item.name">{{item.name}}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">是否必修：</a-col>
          <a-col span="20">
            <a-select
              style="width:100%;"
              v-model="compulsFlag"
              placeholder="请选择是否必修"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option :value="item.value" v-for="item in compulsFlagList" :key="item.value">{{item.label}}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </template>
      <div class="ModalBtn">
        <a-button type="primary" @click="onSave()" :loading="confirmLoading">确定</a-button>
        <a-button style="margin-left:40px;" @click="modalObj.visible = false">取消</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "章节目录",
    dataIndex: "sectionName",
    key: "sectionName",
  },
  {
    title: "主讲人",
    align: "center",
    dataIndex: "teacherIdList",
    scopedSlots: { customRender: "teacherIdList" },
  },
  {
    title: "时长",
    align: "center",
    dataIndex: "duration",
    scopedSlots: { customRender: "duration" },
  },
  {
    title: "终端展示排序",
    align: "center",
    dataIndex: "show",
    scopedSlots: { customRender: "show" },
  },
  {
    title: "目录操作",
    align: "center",
    dataIndex: "Directory",
    scopedSlots: { customRender: "Directory" },
  },
  {
    title: "视频操作",
    align: "center",
    dataIndex: "videoFileId",
    scopedSlots: { customRender: "videoFileId" },
  },
  // {
  //   title: "小节自测操作",
  //   align: "center",
  //   dataIndex: "SelfTest",
  //   scopedSlots: { customRender: "SelfTest" },
  // },
];
import methods from './methods.vue'
export default {
  mixins: [ methods ],
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: ['courseId'],
  // 数据对象
  data () {
    return {
      tabelKey:'',
      columns,
      listData:[],
      loading:false, // 列表加载
      name: '',
      modalObj:{ // 弹窗设置对象
        visible:false,
        title: '',
        type: '',
      },
      InstructorList:[], // 教师列表
      compulsFlag: 1,
      // 是否必修列表
      compulsFlagList:[
        {
          label: '是',
          value: 1
        },{
          label: '否',
          value: 0
        }
      ],
      percent: 0, // 视频上传进度
      uploadLoading: false, // 视频上传状态
      teacherChecked:[],
      confirmLoading: false, 
      videoFileId: 0,  // 视频源id
      videoDuration: 0, // 视频时长,
      setCatalogue: 0, // 是否有目录(1-是，0-否)
    }
  },
  // 事件处理器
  methods: {
    
  },
  // 生命周期-实例创建完成后调用
  created () { 
    
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
  }
}
</script>

<style lang="less" scoped>
@import '../../CourseDetails/style.less';
.radio{
  display: flex;
  margin-top: 0;
  margin-bottom: 20px;
}
.head{
  display: flex;
  justify-content: space-between;
}
.ModalBtn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.submitBtn{
  margin-top: 10px;
}
.required::before{
  content: '*';
  color: red;
  margin-right: 2px;
}
</style>
