<script>
import { uuid } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      videoFinishFlag: true,
    }
  },
  // 事件处理器
  methods: {
    openModal(obj,row){
      obj.visible = true
      if(row){
        obj.sectionName = row.sectionName
        obj.sectionId = row.sectionId
        
        // 视频编辑
        let data = []
        row.teacherIdList.forEach(item=>{
          data.push(item.id)
        })
        if(row.videoFileId) {
          this.compulsFlag = row.compulsFlag === null?undefined:row.compulsFlag;
        }else{
          this.compulsFlag = 1;
        }
        this.teacherChecked = data
        this.videoFileId = row.videoFileId
        this.videoDuration = row.duration

      }
      // 编辑目录
      if(obj.type == 'editDirectory'){
        this.name = row.sectionName
      }else{
        this.editFlag = false;
      }
      this.modalObj = obj
    },
    onCancel(){
      this.name = ''
    },

    // 课程视频更新状态
    updateVideoType(e){
      // 防抖
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        this.$ajax({
        url: '/hxclass-management/course/updateCourseVideoFinish',
        method: 'POST',
        params:{
          courseId: this.courseId,
          videoFinishFlag: e ? 0 : 1,  // 0 持续更新  1 已完结
        }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.$message.success('更新完成')
          } else {
            this.$message.error(res.message)
          }
        })
      }, 1000);
    },

    // 查询授课老师
    getInstructor(){
      this.$ajax({
        url: '/hxclass-management/teacher/select',
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.InstructorList = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    
    // 根据老师id转换老师姓名
    funTeacherIdList(arr){
      let array = []
      let data = []
      arr.forEach(item=>{
        data.push(item.id)
      })
      arr = data
      this.InstructorList.forEach(item=>{
        if(arr.indexOf(item.teacherId) != -1){
          array.push(item.name)
        }
      })
      return array.join('、') || '-'
    },

    // 时间转换 - 秒转时分秒
    formatSeconds(value) {
      if(!value || value == '-')return '00:00'
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

      let res = '';
      if(h !== '00') res += `${h}:`;
      res += `${m}:`;
      res += `${s}`;
      return res;
    },

    // 课程目录上下移动
    moveCatalogue(uid,move,parentId){
      let list = [{
        sectionId: 0,
        childList: this.listData
      }]

      let moveList = []
      let j = 0

      function moveFors(arr){
        arr.forEach((item,index)=>{
          if(item.childList && item.childList.length>0){
            item.childList = moveFors(item.childList)
          }
          if(item.sectionId == parentId){
            moveList = item.childList
          }
          if(item.uid == uid){
            j = index
          }
        })
        return arr
      }
      moveFors(list)
      if(move == 1 && !moveList[j-1]){
        this.$message.error('当前章节不可上移');
        return
      } else if (move == 2 && !moveList[j+1]){
        this.$message.error('当前章节不可下移');
        return
      }

      function fors(arr){
        let i = null
        let data = null
        arr.forEach((item,index)=>{
          if(item.childList && item.childList.length>0){
            item.childList = fors(item.childList)
          }
          if(item.uid == uid){
            i = index
          }
        })
        if(i!=null){
          if(move==1){
            data = arr[i-1]
            arr[i-1] = arr[i]
          }else if(move==2){
            data = arr[i+1]
            arr[i+1] = arr[i]
          }
          arr[i] = data
          i=null
        }
        return arr
      }
      let data = fors(this.listData)
      this.listData = []
      this.listData = data
      this.funMoveCatalogueState() // 更新课程目录上下移动状态
    },

    // 更新课程目录上下移动状态
    funMoveCatalogueState(){
      function fors(arr){
        arr.forEach((item,index)=>{
          if(item.childList && item.childList.length>0){
            item.childList = fors(item.childList)
          }
          if(index==0){
            item.first = 1
          }else{
            item.first = 0
          }
          if(arr.length-1 == index){
            item.last = 1
          }else{
            item.last = 0
          }
        })
        return arr
      }
      let data = fors(this.listData)
      this.listData = []
      this.listData = data
    },

    // tree显示的icon
    expandIcon(props) {
      if(props.record.childList && props.record.childList.length > 0){
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      }else{
        return <span style={{marginRight:8 }}></span>
      }
    },

    // 获取目录详情
    getCourseSection(){
      this.$ajax({
        url: "/hxclass-management/course/getCourseSection",
        params: {
          courseId: this.courseId,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.videoFinishFlag = Number(res.data.videoFinishFlag) ? false : true
            this.setCatalogue = res.data.setCatalogue
          }
        })
        .catch((err) => {
          
        });
    },

    // 获取目录列表
    getContentsList(){
      this.loading = true
      this.tabelKey = '1-1'
      this.$ajax({
        url: "/hxclass-management/course/getManageSectionTree",
        params: {
          courseId: this.courseId,
        },
      })
        .then((res) => {
          this.tabelKey = '2-1'
          this.loading = false
          if (res.code == 200 && res.success) {
            if(res.data.length){
              this.funFormatCatalogue(res.data)
            } else {
              this.listData = []
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          
        });
    },
    // 组装目录结构
    funFormatCatalogue(datas){
      // 课程目录
      function fors(arr){
        arr.forEach(item=>{
          if(item.childList && item.childList.length>0){
            item.childList = fors(item.childList)
          }else{
            item.childList = []
          }
          item.uid = uuid()
          let array = []
          item.teacherList.forEach(items=>{
            array.push({
              id: items.teacherId
            })
          })
          item.teacherIdList = array
          // item.freeTrial = funFreeTrial(item)
        })
        return arr
      }
      this.listData = fors(datas)
    },

    // 导入目录模板
    ImportCatalogTemplate(e){
      this.$ajax({
        url: '/hxclass-management/course/import-course-section',
        method: 'post',
        headers: [
          {type: 'file'}
        ],
        params:{
          file: e.file,
        }
      }).then(res=>{
        if(res.code == 200 && res.success && res.data.length>0){
          this.ImportCatalog(res.data)
          this.$message.success('导入成功')
        }else{
          this.$message.error('数据导入失败，请重新导入')
        }
      }).catch(err=>{
        // console.log(err)
        this.$message.error('数据导入失败，请重新导入')
      })
    },

    // 导入目录入库
    ImportCatalog(data){
      this.$ajax({
        url: '/hxclass-management/course/batchSaveSection',
        method: 'POST',
        params:{
          courseCatalogueList: data,
          courseId: this.courseId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          // 更新目录列表
          this.getContentsList()
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },
    
    // 提交
    onSubmit(saveType){
      this.loading = true;
      let data = []
      function fors(arr,newArr){
        arr.forEach((item,index)=>{
          newArr.push({
            sectionId: 0,
            sectionSortChildList: [],
          })
          newArr[index].sectionId = item.sectionId
          if(item.childList && item.childList.length>0){
            fors(item.childList, newArr[index].sectionSortChildList)
          }
        })
        return newArr
      }

      data = fors(this.listData, data)

      this.$ajax({
        url: '/hxclass-management/course/courseModuleSaveSectionInfo',
        method: 'put',
        params:{
          courseId: this.courseId,
          sectionSortDTOList: data,
          setCatalogue: this.setCatalogue,
          saveType: saveType,  // 保存类型(0-未填写，1-暂存，2-提交)
        }
      }).then(res=>{
        this.loading = false;
        if(res.code == 200 && res.success){
          this.$message.success( (saveType == 1 ? '暂存' : '保存') + '成功!')
          this.$emit('setChildInit',saveType);
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.loading = false;
        this.$message.error(res.message)
      })
    },

    onSave(){
      // 目录新增
      if(this.modalObj.type=='newDirectory' || this.modalObj.type=='newChildrenDirectory'){
        this.onSectionAdd()
      } else if (this.modalObj.type=='editDirectory') {
        this.onSectionEdit()
      } else if (this.modalObj.type=='uploadVideo') {
        this.onVideoAdd()
      }
    },
    // 添加目录
    onSectionAdd(){
      if(!this.name){
        this.$message.warning("请填写目录名称");
        return
      }
      this.confirmLoading = true;
      this.tabelKey = '1-1'
      this.$ajax({
        url: '/hxclass-management/course/saveCourseSection',
        method: 'put',
        params:{
          courseId: Number(this.courseId),
          sectionName: this.name,
          first:0,
          last:1,
          parentId: this.modalObj.sectionId ? this.modalObj.sectionId : 0
        }
      }).then(res=>{
        this.confirmLoading = false;
        if(res.code == 200 && res.success){
          let pid = this.modalObj.sectionId ? this.modalObj.sectionId : 0
          res.data.questionList = []
          res.data.teacherList = []
          let listData = [
            {
              sectionId: 0,
              childList: this.listData
            }
          ]
          function fors(arr){
            arr.forEach(item=>{
              if(item.childList && item.childList.length>0){
                item.childList = fors(item.childList)
              }else{
                item.childList = []
              }
              if(item.sectionId == pid){
                item.childList.push(res.data)
              }
            })
            return arr
          }
          fors(listData)
          this.listData = listData[0].childList
          this.tabelKey = '2-1'
          this.funFormatCatalogue(this.listData)
          
          this.modalObj.visible = false
          this.name = ''
          // this.getContentsList()
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },

    // 编辑目录
    onSectionEdit(){
      this.$ajax({
        url: '/hxclass-management/course/updateCourseSection',
        method: 'POST',
        params:{
          courseId: Number(this.courseId),
          sectionId: this.modalObj.sectionId,
          sectionName: this.name,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          let name = this.name
          let sectionId = this.modalObj.sectionId
          function fors(arr){
            arr.forEach(item=>{
              if(item.childList && item.childList.length>0){
                item.childList = fors(item.childList)
              }else{
                item.childList = []
              }
              if(item.sectionId == sectionId){
                item.sectionName = name
              }
            })
            return arr
          }
          this.listData = fors(this.listData)
          this.modalObj.visible = false
          this.name = ''
          // this.getContentsList()
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },

    // 删除视频
    removeVideo(row){
      this.$ajax({
        url: '/hxclass-management/course/deleteSectionVideo',
        method: 'post',
        params:{
          courseId: Number(this.courseId),
          sectionId: row.sectionId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          function fors(arr){
            arr.forEach(item=>{
              if(item.childList && item.childList.length>0){
                item.childList = fors(item.childList)
              }else{
                item.childList = []
              }
              if(item.sectionId == row.sectionId){
                item.videoFileId = null
                item.duration = null
              }
            })
            return arr
          }
          this.listData = fors(this.listData)
          // this.getContentsList()
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },

    // 章节删除确认
    removeCatalogue(row){
      this.$ajax({
        url: '/hxclass-management/course/getSectionIsDelete',
        params:{
          courseId: Number(this.courseId),
          sectionId: row.sectionId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.onCatalogueDel(row)
        } else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },
    // 确认章节删除
    onCatalogueDel(row){
      this.$ajax({
        url: '/hxclass-management/course/deleteSection',
        method: 'post',
        params:{
          courseId: Number(this.courseId),
          sectionId: row.sectionId,
          first: row.first,
          last: row.last,
          parentId: row.parentId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.modalObj.visible = false
          let listData = [
            {
              sectionId: 0,
              childList: this.listData
            }
          ]
          function fors(arr){
            arr.forEach(item=>{
              if(item.childList && item.childList.length>0){
                item.childList = fors(item.childList)
              }else{
                item.childList = []
              }
              if(item.sectionId == row.parentId){
                item.childList.forEach((subItem,index) => {
                  if(subItem.sectionId == row.sectionId){
                    item.childList.splice(index,1)
                  }
                });
              }
            })
            return arr
          }
          listData = fors(listData)
          this.listData = listData[0].childList
          // this.getContentsList()
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },

    // 视频新增
    onVideoAdd(){
      if(!this.videoFileId){
        this.$message.warning('请上传视频文件')
        return
      }
      this.tabelKey = '1-1'
      this.confirmLoading = true
      this.$ajax({
        url: '/hxclass-management/course/uploadSectionVideo',
        method: 'put',
        params:{
          compulsFlag: this.compulsFlag,
          courseId: Number(this.courseId),
          sectionId: this.modalObj.sectionId,
          duration: Number(this.videoDuration),
          videoFileId: this.videoFileId,
          teacherIdList: this.teacherChecked
        }
      }).then(res=>{
        let sectionId = this.modalObj.sectionId
        let videoFileId = this.videoFileId
        let teacherIdList = this.teacherChecked
        let duration = this.videoDuration
        let compulsFlag = this.compulsFlag === undefined?null:this.compulsFlag
        this.confirmLoading = false
        if(res.code == 200 && res.success){
          function fors(arr){
            arr.forEach(item=>{
              if(item.childList && item.childList.length>0){
                item.childList = fors(item.childList)
              }else{
                item.childList = []
              }
              if(item.sectionId == sectionId){
                item.duration = (parseInt(duration)).toFixed(1)
                item.videoFileId = videoFileId
                item.compulsFlag = compulsFlag
                item.teacherIdList = []
                teacherIdList.forEach(element => {
                  item.teacherIdList.push({id: element})
                  item.teacherList.push({teacherId: element})
                });
              }
            })
            return arr
          }
          this.listData = fors(this.listData)
          this.tabelKey = '2-1'
          this.modalObj.visible = false
          this.teacherChecked = []
          this.videoFileId = 0
          // this.getContentsList()
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(res.message)
      })
    },

    // 改写授课老师搜索条件
    filterInstructorList(inputValue, option){
      return option.key.indexOf(inputValue) != -1
    },

    // 重置文件上传 - 单文件
    customRequest(fileData){
      this.percent = 0
      this.confirmLoading = true
      this.uploadLoading = true
      this.$upload_video({
        file: fileData.file,
        progress: (e)=>{
          this.$set(this, 'percent', e.percent)
        },
        success: (e)=>{
          this.videoFileId = e
          this.getVideoDuration(e)
        }
      })
    },
    // 查询视频播放时长
    getVideoDuration(videoFileId){
      this.$ajax({
        url: '/hxclass-management/cos/get-video-duration',
        params: {
          fileId: videoFileId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.videoDuration = res.data
          this.uploadLoading = false
          this.confirmLoading = false
        }else{
          this.$message.error(res.message)
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.$nextTick(() => {
      // 查询教师
      this.getInstructor()

      // 目录列表
      this.getContentsList()

      // 目录列表
      this.getCourseSection()
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
  },
  // 自定义的侦听器
  watch: {
  }
}
</script>

